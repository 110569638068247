<template>
  <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      width="32"
      height="32"
      rx="16"
      fill="#FF0000"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.6277 10.6946C23.8761 10.9724 24.0551 11.3182 24.1469 11.6974C24.6754 14.076 24.5533 17.8326 24.1572 20.303C24.0654 20.6822 23.8864 21.028 23.6379 21.3058C23.3895 21.5836 23.0803 21.7838 22.7411 21.8864C21.4995 22.2651 16.5022 22.2651 16.5022 22.2651C16.5022 22.2651 11.5049 22.2651 10.2632 21.8864C9.92408 21.7838 9.61487 21.5836 9.36642 21.3058C9.11797 21.028 8.93895 20.6822 8.84717 20.303C8.31563 17.9347 8.46134 14.1758 8.83691 11.7089C8.92868 11.3297 9.10771 10.9839 9.35616 10.7061C9.60461 10.4283 9.91382 10.2281 10.253 10.1255C11.4946 9.74683 16.4919 9.73535 16.4919 9.73535C16.4919 9.73535 21.4892 9.73535 22.7308 10.114C23.07 10.2166 23.3792 10.4168 23.6277 10.6946ZM19.047 16.0002L14.9014 18.6852V13.3153L19.047 16.0002Z"
      fill="white"
    />
  </svg>
</template>
