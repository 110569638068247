<template>
  <svg viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      width="32"
      height="32"
      rx="16"
      fill="#1D52A1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 13.2893H14.2143V11.7025C14.2143 10.9958 14.252 9.86962 14.7857 9.19008C15.3449 8.47445 16.0346 8 17.3571 8C19.5143 8 20.5 8.26446 20.5 8.26446L20.0714 10.7769C19.6123 10.6227 19.1307 10.5335 18.6429 10.5124C17.974 10.5124 17.3571 10.6627 17.3571 11.3058V13.2893H20.2143V15.6694H17.3571V24H14.2143V15.6694H12.5V13.2893Z"
      fill="#1D52A1"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 13.2893H14.2143V11.7025C14.2143 10.9958 14.252 9.86962 14.7857 9.19008C15.3449 8.47445 16.0346 8 17.3571 8C19.5143 8 20.5 8.26446 20.5 8.26446L20.0714 10.7769C19.6123 10.6227 19.1307 10.5335 18.6429 10.5124C17.974 10.5124 17.3571 10.6627 17.3571 11.3058V13.2893H20.2143V15.6694H17.3571V24H14.2143V15.6694H12.5V13.2893Z"
      fill="white"
    />
  </svg>
</template>
